@import "../styles/tools";

.item {
    -webkit-appearance: none;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;  
    width: auto;
    color: $color-body;
    border: none;
    font-size: 15px;
    line-height: 15px;
    height: 40px;
    vertical-align: middle;
    font-weight: 300;
    padding: 9px 15px;
    box-sizing: border-box;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
    border-radius: 5px;
    background: #fff;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    margin-left: 20px;

    &.item--no-margin {
        margin-left: 0;
    }

    &:visited, &:link {
        color: $color-body;
    }

    &:hover {
        background: mix($color-body, #fff, 5%);
    }

    &.active, &.item--active {
        // background: mix(#276EC3, #fff, 10%);
        // color: #276EC3;
        // padding-bottom: 14px;
    }
}

$badgeSize: 20px;

.item__badge {
    position: absolute;
    min-width: $badgeSize;
    height: $badgeSize;
    line-height: $badgeSize - 1px;
    font-size: 10px;
    border-radius: $badgeSize * 0.5;
    background: $color-red--text;
    padding: 0 $badgeSize * 0.2;
    color: #FFF;
    z-index: 2;
    transform: translate(17px, -17px) scale(0);
    transition: all 0.2s ease-in-out;

    &.item__badge--active {
        transform: translate(17px, -17px) scale(1);
    }
}

.item--without-text {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    > .icon {
        top: 0;
    }
}

.item--small {
    width: 38px;
    height: 38px;
}

@media (max-width: 1610px) {
    .title--removed {
        display: none;
    }
}

@media (max-width: 1410px) {
    .item {
        &.item--removed {
            display: none;
        }
    }
}

@include media-tablet-portrait {
    .item {
        margin-left: 10px;
    }
}

@include media-tablet {
    .title {
        display: none;
    }

    .item:not(:last-child) {
        .icon {
            margin-right: 0;
        }
    }
}